<template>

  <div
    class="app-contact-us"
    role="content">

    <div class="app-contact-info">

      <div>Oceanographic Data Center, Institute of Oceanology，Chinese Academy of Sciences（CAS）
      </div>

      <ul>

        <li v-for="(item, i) in data"
          :key="i">

          <i :class="['iconfont', item.icon]"></i>

          <span>{{ item.text }}
          </span>

        </li>

      </ul>

    </div>

    <div class="app-contact-item">

      <img
        src="/img/map.png"
        alt="MAP-IMAGE">

    </div>

  </div>

</template>

<script>
export default {
  name: 'app-about-us',
  data () {
    return {
      data: [
        {
          icon: 'icon-phone',
          text: 'Tel: 0532-82896760'
        },
        {
          icon: 'icon-youjian',
          text: 'Email: sjzx@qdio.ac.cn'
        },
        {
          icon: 'icon-dizhi',
          text: 'Address: No.7 Nanhai Road, Qingdao, Shandong Province'
        },
        {
          icon: 'icon-pencil',
          text: 'Postcode: 266071'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.app-contact-us {
  /* background-image: url('/img/map.png');
  background-size: contain;
  background-repeat: no-repeat; */
  background-color: white;
  display: flex;
  flex-direction: row;
  height: 400px;
  margin: {
    bottom: 50px;
    top: 50px;
  };
  padding: {
    bottom: 20px;
    left: 20px;
    right: 20px;
    top: 20px;
  };
  position: relative;
  width: 1200px;
}

.app-contact-info {
  background-color: rgba(245, 246, 250, 1);
  height: 100%;
  padding: {
    bottom: 30px;
    left: 30px;
    right: 30px;
    top: 20px;
  };
  width: 480px;

  div {
    color: rgba(51, 51, 51, 1);
    font: {
      size: 20px;
      weight: bold;
    };
    text-align: left;
  }

  ul {
    list-style-type: none;
    margin-top: 30px;
    text-align: left;

    li {
      align-items: center;
      display: flex;
      flex-direction: row;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
      i {
        background-color: rgba(238, 150, 19, 1);
        border-radius: 28px;
        color: #FFFFFF;
        flex-shrink: 0;
        font-size: 20px;
        height: 28px;
        line-height: 28px;
        margin-right: 18px;
        text-align: center;
        width: 28px;
      }
      span {
        color: rgba(51, 51, 51, 1);
        font: {
          size: 16px;
          weight: bold;
        }
      }
    }
  }
}
.app-contact-item {
  height: 100%;
  width: 720px;

  img {
    height: 100%;
    width: 100%;
  }
}
</style>>
